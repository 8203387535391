import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './components/HomePage.vue';
import PrivacyPolicy from './components/PrivacyPolicy.vue';
import SupportPage from './components/SupportPage.vue';
import AndroidPage from './components/AndroidPage.vue';
import iOSPage from './components/iOSPage.vue';

const routes = [
  { path: '/', component: HomePage },
  { path: '/privacy-policy', component: PrivacyPolicy },
  { path: '/support', component: SupportPage },
  { path: '/android', component: AndroidPage },
  { path: '/ios', component: iOSPage }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
