<template>
    <div class="container">
      <h2>Privacy Policy</h2>

      <p>1. Introduction</p>
        <p>This Privacy Policy sets out how we, ANC Digital, use and protect your personal data that you provide to us, or that is otherwise obtained or generated by us, in connection with our app. For the purposes of this Privacy Policy, 'we', 'us' and 'our' refers to ANC Digital, and 'you' refers to you, the user of the Services.</p>
 
        <p>1.1. Privacy Principles</p>
        <p>We have one fundamental principle when it comes to collecting and processing private data:</p>
        <p>We don't use your data to show you ads.</p>
 
        <p>1.3. This Privacy Policy explains the following:</p>
        <p>• the legal basis for processing your personal data;</p>
        <p>• what personal data we may collect from you;</p>
        <p>2. Legal Ground for Processing Your Personal Data</p>
        <p>• We process your personal data on the ground that such processing is necessary to further our legitimate interests (including: (1) providing effective and innovative Services to our users; and (2) to detect, prevent or otherwise address fraud or security issues in respect of our provision of Services), unless those interests are overridden by your interest or fundamental rights and freedoms that require protections of personal data.</p>
 
        <p>3. What Personal Data We Use</p>
        <p>3.1 ANC Digital is a social media service. You provide your mobile number and basic account data (which may include Name, Surname and profile picture)</p>
        <p>3.2 ANC Digital uses phone numbers as unique identifiers.</p>
        <p>3.3 We do not use cookies for profiling or advertising.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: "PrivacyPolicy",
  };
  </script>
  
  <style scoped>
  /* Add your custom styles here */
  </style>
  