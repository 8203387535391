<template>
  <div class="container">
    <section class="py-5">
      <div class="text-center">
        <img src="@/assets/logo.png" alt="Task Management" class="img-fluid rounded" style="width: 500px; height: auto;">
        <h1 class="display-4 mb-4">Welcome to ANC Digital</h1>
        <p class="lead mb-4">Your all-in-one digital solution</p>
        
      </div>
    </section>

    <section class="py-5">
      <div class="row">
        <div class="col-md-6">
          <h2 class="mb-4">Engage with the community</h2>
          <p>Engage and connect with other users by seamlessly sharing your posts and content. Whether it's captivating stories, informative articles, or inspiring visuals, our ANC Digital empowers you to effortlessly share what's on your mind with a vibrant community of like-minded individuals.

Our platform invites you to participate in the conversation by reacting to posts that resonate with you. Show your support with a simple tap, or delve deeper into meaningful discussions through insightful comments. With every interaction, users become active participants in a dynamic exchange of ideas.

With each shared post, you contribute to a tapestry of shared experiences, creating connections that transcend geographical boundaries and cultural divides. Whether it's a heartfelt message, a thought-provoking article, or a breathtaking image, your contributions enrich the collective conversation, fostering connections that inspire, inform, and uplift.</p>
        </div>

        <div class="col-md-2 text-center">
          <img src="@/assets/02.png" alt="Task Management" class="img-fluid rounded" style="width: 200px; height: auto;">
        </div>

        <div class="col-md-2 text-center">
          <img src="@/assets/04.png" alt="Task Management" class="img-fluid rounded" style="width: 200px; height: auto;">
        </div>

        <div class="col-md-2 text-center">
          <img src="@/assets/03.png" alt="Task Management" class="img-fluid rounded" style="width: 200px; height: auto;">
        </div>
      </div>
    </section>

    <section class="py-5">
      <div class="row">
        <div class="col-md-3 text-center">
          <img src="@/assets/05.png" alt="Team Collaboration" class="img-fluid rounded" style="width: 200px; height: auto;">
        </div>
        <div class="col-md-3 text-center">
          <img src="@/assets/10.png" alt="Team Collaboration" class="img-fluid rounded" style="width: 200px; height: auto;">
        </div>
        <div class="col-md-6">
          <h2 class="mb-4">Keep up to date with the latest events</h2>
          <p>With ANC Digital, attending ANC events is just a tap away. RSVP to ANC gatherings effortlessly, ensuring you never miss out on the action. Plus, with your digital ID, event entry becomes a breeze. ANC Digital makes it simple to integrate ANC events into your busy schedule. With just a single tap, add ANC gatherings to your calendar, ensuring you're always in the know.</p>
        </div>
      </div>
    </section>

    <section class="py-5">
      <div class="row">
        <div class="col-md-6">
          <h2 class="mb-4">Watch ANC podcasts</h2>
          <p>With ANC Digital, staying connected to ANC's latest content is easier than ever. Listen to podcasts on-demand, allowing you to explore topics at your own pace and convenience. Hear what the ANC has to say through ANC Digital's podcast and live broadcast features. Engage with thought-provoking content, gain valuable insights, and stay informed about the issues that shape our country. </p>
        </div>
        <div class="col-md-6 text-center">
          <img src="@/assets/08.png" alt="Team Collaboration" class="img-fluid rounded" style="width: 200px; height: auto;">
        </div>
      </div>
    </section>


    <section class="py-5">
      <div class="row">
        <div class="col-md-6 text-center">
          <img src="@/assets/07.png" alt="Team Collaboration" class="img-fluid rounded" style="width: 200px; height: auto;">
        </div>

        <div class="col-md-6">
          <h2 class="mb-4">See the latest news</h2>
          <p>Discover a wealth of news content with ANC Digital. Seamlessly browse and search through a diverse range of news sources, spanning both local and international coverage. Stay informed and empowered with access to a wide array of perspectives, ensuring you're always up-to-date on the latest happenings from around the globe.</p>
        </div>
      </div>
    </section>



  </div>
</template>

<script>
export default {
  name: "HomePage",
};
</script>

<style scoped>
/* Add your custom styles here */
</style>
