<template>
    <div class="container">
        <div class="support-details">
        <h2>Contact Support</h2>
        <p>If you have any questions or need assistance, our support team is here to help!</p>

        <p>Note that if you wish to delete your account, you may do so by simply tapping the profile button in the home screen and selecting "Delete Account". This will remove any data you've entered into your profile. You may also simply email us to remove your account on your behalf.</p>

        <div class="contact-info">
            <p><strong>Email:</strong> support@ancdigital.co.za</p>
        </div>
        <div class="support-hours">
            <h3>Support Hours:</h3>
            <p><strong>Monday - Friday:</strong> 9:00 AM - 6:00 PM</p>
            <p><strong>Saturday - Sunday:</strong> Closed</p>
        </div>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'SupportPage'
  }
  </script>
  
  <style scoped>
  .support-details {
    margin: 20px;
  }
  
  .contact-info {
    margin-bottom: 15px;
  }
  
  .support-hours {
    margin-top: 20px;
  }
  </style>
  