<template>
    <div class="container">
      <div class="header">
        <h3>ANC Digital for iOS</h3>
      </div>
      <div class="content">
        <p>Download our app from the App Store:</p>
        <a :href="appStoreLink" target="_blank">
          <img src="@/assets/appstore1.png" alt="Download from the App Store" class="play-store-badge">
        </a>
      </div>
      
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        appStoreLink: 'https://apps.apple.com/za/app/anc-digital/id6480261388' // Replace this with your actual Play Store link
      };
    }
  };
  </script>
  
  <style scoped>
  .container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .header {
    margin-bottom: 20px;
  }
  
  .content {
    margin-bottom: 40px;
  }
  
  .play-store-badge {
    width: 200px;
    transition: transform 0.3s ease;
  }
  
  .play-store-badge:hover {
    transform: scale(1.05);
  }
  
  .footer {
    margin-top: 40px;
  }
  
  .social-icons {
    display: flex;
    justify-content: center;
  }
  
  .icon-link {
    margin: 0 10px;
    font-size: 24px;
    color: #555;
    transition: color 0.3s ease;
  }
  
  .icon-link:hover {
    color: #007bff;
  }
  </style>
  