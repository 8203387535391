<template>
  <div class="container">
    <div class="header">
      <h3>ANC Digital for Android</h3>
    </div>
    <div class="playstore">
      <a :href="playStoreLink" target="_blank">    
        <img src="@/assets/playstore1.png" alt="Download from Play Store" class="play-store-badge">
      </a> 
    </div>
    <div class="apk">
      <p class="content">If your Android device lacks support for Google services, or if you choose not to use them, you can download the ANC Digital APK directly:</p>
      <a href="/anc-digital-beta.apk" download="anc-digital-beta.apk" class="apk-download-button">
        <img src="@/assets/apk.png" alt="Download the APK" class="play-store-badge">
      </a>
      <p class="coming-soon">After downloading, tap on the APK file from your downloads list or file manager. You may need to enable 'Install unknown apps' from your settings if prompted. Follow the installation steps to complete setup.</p>
    </div>    
  </div>
</template>

<script>
export default {
  data() {
    return {
      playStoreLink: 'https://play.google.com/store/apps/details?id=com.ancdigital.myanc' // Replace this with your actual Play Store link
    };
  }
};
</script>

<style scoped>
.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}

.header {
  margin-bottom: 20px;
}

.content {
  margin-top: 40px;
  margin-bottom: 40px;
}

.coming-soon {
  margin-top: 20px;
  font-style: italic;
  color: #555;
}

.coming-soon {
  margin-top: 20px;

}

.play-store-badge, .apk-badge {
  width: 180px; /* Slightly smaller for a more refined look */
  transition: transform 0.3s ease;
  cursor: pointer;
}

.play-store-badge:hover, .apk-badge:hover {
  transform: scale(1.1);
}

.icon-link {
  margin: 0 10px;
  font-size: 24px;
  color: #555;
  transition: color 0.3s ease;
}

.icon-link:hover {
  color: #007bff;
}
</style>
