<template>
  <div id="app">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <div class="container">
        <router-link to="/" class="navbar-brand">ANC Digital</router-link>
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
          aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav">
            <li class="nav-item">
              <router-link to="/" class="nav-link">Home</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/privacy-policy" class="nav-link">Privacy Policy</router-link>
            </li>
            <li class="nav-item">
              <router-link to="/support" class="nav-link">Support</router-link>
            </li>
          </ul>
          <ul class="navbar-nav ms-auto">
            <li class="nav-item me-4">
              <a :href="appStoreLink">
                <img src="@/assets/appstore1.png" alt="App Store" class="img-fluid rounded" style="width: 150px; height: auto;">
              </a>
            </li>
            <li class="nav-item">
              <a :href="playStoreLink">
                <img src="@/assets/playstore1.png" alt="Google Play Store" class="img-fluid rounded" style="width: 150px; height: auto;">
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div class="content">
      <router-view></router-view>
    </div>

    <!-- Footer -->
    <footer class="bg-dark text-light py-4">
      <div class="container text-center">
        <ul class="list-inline mb-0">
          <li class="list-inline-item me-4"><a href="https://twitter.com"><font-awesome-icon :icon="['fab', 'twitter']" style="color: var(--icon-color);" /></a></li>
          <li class="list-inline-item me-4"><a href="https://facebook.com"><font-awesome-icon :icon="['fab', 'facebook']" style="color: var(--icon-color);" /></a></li>
          <li class="list-inline-item me-4"><a href="https://youtube.com"><font-awesome-icon :icon="['fab', 'youtube']" style="color: var(--icon-color);" /></a></li>
          <li class="list-inline-item me-4"><a href="https://example.com"><font-awesome-icon :icon="['fas', 'globe']" style="color: var(--icon-color);" /></a></li>
        </ul>

        <p class="mt-2 mb-0">© 2024 ANC Digital. All rights reserved.</p>
      </div>
    </footer>
  </div>
</template>

<script>
// Import Font Awesome icons
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Add Font Awesome icons to the library
library.add(fab, fas)

export default {
  name: 'App',
  components: {
    FontAwesomeIcon
  },
  data() {
    return {
      playStoreLink: '/android',
      appStoreLink: '/ios' // Update this with your actual Android page route
    };
  }
}
</script>

<style>
:root {
  --icon-color: #ffcb03; /* Default icon color */
}

/* Apply color to all font-awesome-icon components */
.font-awesome-icon {
  color: var(--icon-color) !important;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
}
</style>
